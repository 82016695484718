import MainWrapper from "sections/main/MainWrapper"

import { Loadable } from "components"
import { RestrictGuard } from "context/guards"
import { lazy } from "react"
import { Outlet } from "react-router-dom"

const UserProfile = Loadable(lazy(() => import("pages/user/profile")))
const UserTransactions = Loadable(lazy(() => import("pages/user/transactions")))

const UserOrders = Loadable(lazy(() => import("pages/user/orders")))
const UserBolgariaOrders = Loadable(lazy(() => import("pages/user/bolgaria-orders")))
const UserDubaiOrders = Loadable(lazy(() => import("pages/user/dubai-orders")))

const MainRoutes = {
    path: "/user",
    element: (
        <RestrictGuard minRole={1} redirect="/">
            <MainWrapper>
                <Outlet />
            </MainWrapper>
        </RestrictGuard>
    ),
    children: [
        {
            path: "profile",
            element: <UserProfile />
        },
        {
            path: "transactions",
            element: <UserTransactions />
        },
        {
            path: "orders",
            element: <UserOrders />
        },
        {
            path: "bolgaria-orders",
            element: <UserBolgariaOrders />
        },
        {
            path: "dubai-orders",
            element: <UserDubaiOrders />
        }
    ]
}

export default MainRoutes
