import { Box, CircularProgress, styled } from "@mui/material"
import { Suspense } from "react"

const LoaderWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2001,
    width: "100%",
    height: "100%",
    "& > * + *": {
        marginTop: theme.spacing(2)
    }
}))

export const Loader = () => (
    <LoaderWrapper>
        <CircularProgress color="primary" />
    </LoaderWrapper>
)

export const Loadable = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    )

export const LoaderHandler = () => {
    return (
        <Box sx={{ p: 2, width: "100%", height: "300px", display: "flex" }} justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
        </Box>
    )
}
