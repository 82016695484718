import axios from "libs/axios"
import notyf from "libs/notyf"

import { Loader } from "components"
import { useAppDispatch, useAppSelector } from "interfaces/redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UserCenters, UserProfile } from "services/user"
import { centersSlice } from "store/reducers/centers"
import { sessionSlice } from "store/reducers/session"
import formatCenters from "utils/formatCenters"

export const SessionContext = ({ children }: { children: JSX.Element }) => {
    const [loading, setLoading] = useState<boolean>(true)

    const session = useAppSelector((state) => state.session)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const init = async () => {
            const token = localStorage.getItem("token")

            token && dispatch(sessionSlice.actions.updateToken(token))

            setLoading(false)
        }

        init()
    }, [])

    useEffect(() => {
        if (session.token) {
            axios.defaults.headers.Authorization = session.token

            const parseProfile = async () => {
                const { result, error } = await UserProfile()

                if (result) {
                    dispatch(sessionSlice.actions.updateUser(result))
                }

                return error || ""
            }

            const parseCenters = async () => {
                const { result, error } = await UserCenters()

                if (result) {
                    dispatch(centersSlice.actions.set(formatCenters(result)))
                }

                return error || ""
            }

            const init = async () => {
                const error = await parseProfile()
                if (error) {
                    notyf.error(error)

                    navigate(process.env.REACT_APP_USER_HOME || "", { replace: true })

                    setLoading(false)

                    return
                }

                const errors = await Promise.all<string>([parseCenters()])

                let good = true
                for (let i in errors) {
                    if (errors[i]) {
                        notyf.error(errors[i])

                        good = false
                    }
                }

                setLoading(false)
            }

            setLoading(true)

            init()
        }
    }, [session.token])

    if (loading) {
        return <Loader />
    }

    return children
}
