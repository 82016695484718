import { CalendarOutlined, SnippetsOutlined, UserOutlined } from "@ant-design/icons"
import { IDrawerSchemaCategory } from "interfaces/sections/main/drawer"

export const MainDrawerSchema: IDrawerSchemaCategory = {
    name: "Основное",
    items: [
        {
            name: "Профиль",
            icon: UserOutlined,
            path: "/user/profile"
        },
        {
            name: "Мои заказы (Франция)",
            icon: CalendarOutlined,
            path: "/user/orders"
        },
        {
            name: "Мои заказы (Болгария)",
            icon: CalendarOutlined,
            path: "/user/bolgaria-orders"
        },
        {
            name: "Мои заказы (ОАЭ)",
            icon: CalendarOutlined,
            path: "/user/dubai-orders"
        },
        {
            name: "Мои операции",
            icon: SnippetsOutlined,
            path: "/user/transactions"
        }
    ]
}

export const AdminDrawerSchema: IDrawerSchemaCategory = {
    name: "Администратор",
    items: [
        {
            name: "Все пользователи",
            icon: UserOutlined,
            path: "/admin/users"
        },
        {
            name: "Все заказы (Франция)",
            icon: CalendarOutlined,
            path: "/admin/orders"
        },
        {
            name: "Все заказы (Болгария)",
            icon: CalendarOutlined,
            path: "/admin/bolgaria-orders"
        },
        {
            name: "Все заказы (ОАЭ)",
            icon: CalendarOutlined,
            path: "/admin/dubai-orders"
        }
    ]
}
